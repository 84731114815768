body{
  margin: 0;
  /* font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
  font-family: "Trebuchet MS",Arial,Helvetica,sans-serif;
  /* font-size: 1rem; */
  /* font-weight: 400; */
  line-height: 1.2;
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0px;
  padding: 0px;
}
p{
  margin: 0px;
  font-size: 12px;
}
h5{
  margin: 0px;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 990px;
}
.box-header{
  text-decoration: none;
}

.page{
  padding: 15px;
}
.page p{
  font-size: 14px;
}

.tables-indicador{
  margin-bottom: 30px;
  width: 700px;
}
.tables-indicador th{
  width: 200px;
}

.table-full{
  width: 100%;
}

.tables-indicador,
.tables-indicador th,
.tables-indicador td {
  border: 1px solid black;
  border-collapse: collapse;
}
.tables-indicador th,
.tables-indicador td {
  padding: 5px;
  text-align: left;
}

.logo-fiocruz {
  display: inline-block;
  margin-top: 80px;
  margin-left: 10px;
  width: 430px;
  height: 65px;
  background: url(../img/logo-fiocruz-semfundo.png) top left no-repeat;
  overflow: hidden;
}

header.header{
  background: url(../img/fundo_cabecalho.gif) top center repeat-x;
  height: 151px;
  /* margin-top: -21px; */
}

.header-com-torres{
  background: url(../img/torres_topo.png) bottom center no-repeat;
  height: 152px;
}
.container-com-torres{
  background-image: url(../img/torres_base.png);
  background-position: top center;
  background-repeat: no-repeat;
}

.map-header .missao,
.map-header .visao{
  background: rgb(98,164,53);
  background: linear-gradient(0deg, rgba(98,164,53,1) 0%, rgba(122,182,90,1) 100%);
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}

.map-header{
  display: flex;
  margin-bottom: 15px;
}
.map-header .missao{
  margin-right: 10px;
}
.map-header .visao{
  margin-left: 10px;
}
.map-header .missao h5,
.map-header .visao h5{
  margin-top: 0px;
  margin-bottom: 5px;
}
.map-header .missao p,
.map-header .visao p{
  font-size: 13px;
}

.map-header .missao a,
.map-header .visao a{
  color: #fff;
  text-decoration: none;
}



.page-map{
  background: rgb(208,230,194);
  background: linear-gradient(0deg, rgba(208,230,194,1) 0%, rgba(255,255,255,1) 100%);
}

.rotate {
  transform: rotate(-90deg);
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.ms-shadow {
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.75);
}

/*  mapa legado  */
.map-legado-grid{
  display: grid;
  height: 130px;
  grid-template-areas:
  'header row1item0 row1item1 row1item2 row1item3 row1item4 row1item5'
  'header row2item0 row2item1 row2item2 row2item3 row2item4 row2item5';
  grid-gap: 10px 6px;
}
.background-legado{
  background-color: #A8CE8C;
  width: 98%;
  height: 110px;
  margin-bottom: -120px;
  border-radius: 15px;
  margin-top: 20px;
}
.map-legado .box-header{
  background: #92D14E;
  color: #6DA03D;
  border-radius: 15px;
  grid-area: header;
  width: 50px;
  height: 40px;
  padding: 55px 5px 35px 5px;
}
.map-legado .box-header h5{
  width: 100px;
  text-align: center;
  margin-left: -24px;
}
.map-legado .box-legado{
  background: #92D14E;
  border-radius: 15px;
  font-size: 11px;
  /* color: #74AC41; */
  color: #6DA03D;
  text-align: center;
  width: 140px;
  position: relative;

}

.map-legado .box-legado p{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding:0px 8px;
  font-size: 1.06em;
  text-shadow: 0.04em 0.04em #009900;
}


.map-processos .box-processo p,
.map-processos .box-processo h5,
.map-compromissos .box-compromisso p,
.map-resultado .box-resultado p,
.map-resultado .box-bar-sub h5{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding:0px 8px;
  font-size: 12px;
}

.background-center-map{
  display: grid;
  height: 380px;
  grid-template-areas:
  'back0 back1 back2 back3 back4 back5';
  grid-gap: 10px 6px;
  margin-left: 70px;
  margin-top: 10px;
  margin-bottom: -380px;
}
.back-box{
  width: 60px;
  background-color: #88A6DA;
  margin-left: 40px;
}


/*  mapa resultado  */
.map-resultado-grid{
  display: grid;
  height: 150px;
  grid-template-areas:
  'header barsub barsub barsub barsub barsub barsub'
  'header row2item0 row2item1 row2item2 row2item3 row2item4 row2item5';
  grid-gap: 10px 6px;
  margin-bottom: 20px;
}
.map-resultado .box-header{
  background: #F5BF00;
  color: #9F600A;
  border-radius: 15px;
  grid-area: header;
  width: 50px;
  height: 50px;
  padding: 68px 5px 40px 5px;
  background: rgb(231,183,1);
  background: linear-gradient(0deg, rgba(231,183,1,1) 0%, rgba(255,197,61,1) 100%);
}
.map-resultado .box-header h5{
  width: 100px;
  text-align: center;
  margin-left: -23px;
}
.map-resultado .box-bar-sub{
  grid-area: barsub;
  /* background: #F5BF00; */
  border-radius: 15px;
  color: #9F600A;
  position: relative;
  text-align: center;
  height: 40px;
  background: rgb(231,183,1);
  background: linear-gradient(0deg, rgba(231,183,1,1) 0%, rgba(255,197,61,1) 100%);
}
.map-resultado .box-bar-sub h5{
  width: 100%;
}
.map-resultado .box-resultado{
  /* background: #F5BF00; */
  border-radius: 15px;
  /* color: #74AC41; */
  color: #9F600A;
  text-align: center;
  width: 140px;
  height: 106px;
  position: relative;
  background: rgb(231,183,1);
  background: linear-gradient(0deg, rgba(231,183,1,1) 0%, rgba(255,197,61,1) 100%); 
}
.map-resultado .box-resultado p{
  font-size: 13px;
}



/*  mapa Compromissos  */
.map-compromisso-grid{
  display: grid;
  height: 235px;
  grid-template-areas:
  'header row1item0 row1item1 row1item2 row1item3 row1item4 row1item5'
  'header row2item0 row2item1 row1item2 row1item3 row1item4 row1item5'
  'header row3item0 row3item1 row3item2 row3item3 row3item4 row3item5'
  'header row3item0 row4item0 row3item2 row4item1 row3item4 row3item5';
  grid-gap: 10px 6px;
  margin-bottom: 60px;
}
.map-compromissos .box-header{
  background: #F5BF00;
  color: #3169A0;
  border-radius: 15px;
  grid-area: header;
  width: 50px;
  height: 100px;
  padding: 130px 5px 40px 5px;
  background: rgb(66,137,202);
  background: linear-gradient(0deg, rgba(66,137,202,1) 0%, rgba(112,165,216,1) 100%);
}
.map-compromissos .box-header h5{
  width: 140px;
  text-align: center;
  margin-left: -43px;
}

.map-compromissos .box-compromisso{
  /* background: #F5BF00; */
  border-radius: 15px;
  /* color: #74AC41; */
  color: #3169A0;
  text-align: center;
  width: 140px;
  height: 60px;
  position: relative;
  background: rgb(66,137,202);
  background: linear-gradient(0deg, rgba(66,137,202,1) 0%, rgba(112,165,216,1) 100%);
}
.map-compromissos .box-compromisso p{
  font-size: 12px;
}

.box-compromisso.row1item0{grid-area: row1item0;}
.box-compromisso.row1item1{grid-area: row1item1;}
.box-compromisso.row1item2{
  grid-area: row1item2;
  height: 130px;
}

.box-compromisso.row1item3{
  grid-area: row1item3;
  height: 130px;
}
.box-compromisso.row1item4{
  grid-area: row1item4;
  height: 130px;
}
.box-compromisso.row1item5{
  grid-area: row1item5;
  height: 130px;
}

.box-compromisso.row2item0{grid-area: row2item0;}
.box-compromisso.row2item1{grid-area: row2item1;}

.box-compromisso.row3item0{
  grid-area: row3item0;
  height: 130px;
}
.box-compromisso.row3item1{grid-area: row3item1;}
.box-compromisso.row3item2{
  grid-area: row3item2;
  height: 130px;
}
.box-compromisso.row3item3{
  font-size: 10px;
  grid-area: row3item3;
}

.box-compromisso.row3item3 p{
  font-size: 11px;
}

.box-compromisso.row3item4{
  grid-area: row3item4;
  height: 130px;
}
.box-compromisso.row3item5{
  grid-area: row3item5;
  height: 130px;
  
}


.box-compromisso.row4item0{grid-area: row4item0;}
.box-compromisso.row4item1{grid-area: row4item1;}

.box-compromisso.row4item0 p{
  font-size: 0.7rem;
}






/*  mapa processos  */
.map-processos {
  margin: 0px 0px;
  padding-bottom: 40px;
}
.map-processo-grid{
  display: grid;
  height: 120px;
  grid-template-areas:
  'header row1item0 row1item1 row1item2 row1item3 row1item4'
  'header row2item0 row2item1 row2item2 row2item3 row2item4'
  'header row3item0 row3item1 row3item2 row3item3 row3item4';
  grid-gap: 10px 6px;

}
.background-processo{
  background-color: #F6AF83;
  width: 98%;
  height: 110px;
  margin-bottom: -120px;
  border-radius: 15px;
  margin-top: 20px;
}
.map-processos .box-header{
  color: #C72A10;
  border-radius: 15px;
  grid-area: header;
  width: 50px;
  height: 40px;
  padding: 55px 5px 35px 5px;
  background: rgb(233,110,27);
  background: linear-gradient(0deg, rgba(233,110,27,1) 0%, rgba(245,134,74,1) 100%);

}
.map-processos .box-processo{
  border-radius: 15px;
  font-size: 13px;
  /* color: #74AC41; */
  color: #C72A10;
  text-align: center;
  width: 170px;
  position: relative;
  background: rgb(233,110,27);
  background: linear-gradient(0deg, rgba(233,110,27,1) 0%, rgba(245,134,74,1) 100%);
}

.map-processos .box-processo h5{
  width: 90%;
}
.map-processos .box-header h5{
  width: 70px;
  text-align: center;
  margin-left: -13px;
}
.box-processo.row3item3 p{
  width: 90%;
}

.img-table{
  width: 100%;
}

.box-projetos .projeto{
  display: block;
  color: #000;
  text-decoration: none;
}
.box-projetos .projeto:hover{
  opacity: 0.8;
}
.box-projetos ol{
  padding-left: 20px;
}
.box-projetos ol li{
  margin-bottom: 5px;
}

.back-map{
  color: #fff;
  float: right;
  margin-top: 120px;
  margin-right: 20px;
}
.title-page-link{
  color: #000;
  /* text-decoration: none; */
  margin-bottom: 10px;
  display: block;
}